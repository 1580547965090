/**
 * ajax Load Page JS
 */

import 'whatwg-fetch';
import Overlay from './overlay';
import Scrollto from './scrollto';

export default class AjaxLoadImage {

  constructor(ajaxlinks = null) {
    this.ajaxlinks = (ajaxlinks) ? ajaxlinks : [...document.querySelectorAll('[data-ajax-link]')];
    this.ajaxOverlay = document.querySelector('#ajaxOverlay');
    this.loading = document.querySelector('.loading');
    this.overlayPage = document.querySelector('.overlayPage');
    this.contentOverlay = document.querySelector('.contentOverlay');
    this.bmLogoLoading = document.getElementById('bmLogoLoading');
    this.newPage = null;
    this.img = null;
    this.className = null;
    this.pageurl = window.location.href.replace(/#.*$/, "");
    this.getURL = this.getURL.bind(this);
    this.setURL = this.setURL.bind(this);
    this.linkURL = this.linkURL.bind(this);
  }

  init() {
    this.st = new Scrollto();
    this.overlay = new Overlay();
    this.img = document.createElement("img");
    this.img.setAttribute('class', 'img');
    this.overlay.overlayPage.appendChild(this.img);
    this.overlay.delegate(this.closeOverlay.bind(this));
    // this.registerEvents(this.ajaxlinks);
  }

  registerEvents(ajaxlinks) {
    if (ajaxlinks) {
      ajaxlinks.forEach(link => link.addEventListener('click', this.getURL));
    }
  }

  resetEvents() {
    this.ajaxlinks.forEach(link => link.removeEventListener('click', this.getURL));
  }

  getURL(e) {
    e.preventDefault();
    const link = e.currentTarget;
    const resourceURI = link.href;
    this.prepareURL(resourceURI);
  }

  setURL(URL){
    this.prepareURL(URL);
  }

  setClass(ClassName){
    this.className = ClassName;
    this.contentOverlay.classList.add(this.className);
  }

  setLink(linkEventURL){
    this.img.classList.add('link');
    this.eventurl = linkEventURL;
    this.img.addEventListener('click', this.linkURL)
  }
  
  linkURL(){
    if(this.eventurl){
      window.open(this.eventurl, '_blank');
      // window.location.href = this.eventurl;
    }
  }

  prepareURL(resourceURI){
    this.pageurl = resourceURI;
    let pos = window.pageYOffset;
    this.overlay.checkMiniWin(true);
    if (!this.overlay.status) this.overlay.noscrollOverlay();

    this.fetchContent(resourceURI);
  }

  
  fetchContent(resourceURI) {
    this.overlay.openShowOverlay();

    fetch(resourceURI).then((response) => {
      if (response.ok) {
      // return Promise.resolve(response);
      return response;
      } 
    })
    .then(response => response.arrayBuffer())
    .then(buffer => {
        this.overlay.openContainerInOverlay();
        var base64Flag = 'data:image/jpeg;base64,';
        var imageStr = this.arrayBufferToBase64(buffer);
        this.overlay.overlayBox.querySelector('.img').src = base64Flag + imageStr;
        this.overlay.openContentInOverlay();
    });

  }

  renderContent(page) {
    this.overlay.openContainerInOverlay();
    let parser = new DOMParser();
    let doc = parser.parseFromString(page, 'text/html');
    this.newPage = doc.querySelector('#ajaxPage');

    this.swap(doc, '#ajaxPage');
    this.overlay.openContentInOverlay();
    this.overlay.topscroll();
  }

  swap(doc, name) {
    let newContents = doc.querySelector(name);
    let ajaxOverlay = document.querySelector('#ajaxOverlay');
    this.ajaxPage = ajaxOverlay.querySelector('#ajaxPage');
    this.ajaxPage.parentNode.replaceChild(newContents, this.ajaxPage);
  }

  closeOverlay() {
    let content = document.querySelector('#ajaxPage');
    this.contentOverlay.classList.remove(this.className);
    content.innerHTML = '';
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  };
}

