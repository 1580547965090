
import Swiper from 'swiper';

export default class FeatureNews {
  constructor() {
    this.container = document.querySelector('.slideshow-navss-thumbs-inner');
  }
  
  init() {
    this.setSwiper();
  }

  setSwiper() {
    this.sideSwiper = new Swiper(this.container, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: '.slideshow-navss-nav-right',
        prevEl: '.slideshow-navss-nav-left',
      },
      autoplay: {
        delay: 6000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

}




