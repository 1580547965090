// Accordion //
import { next, getPosition } from '../helpers/helpers';
import Scrollto from './scrollto';

export default class Accordion {

    constructor(acc) {
        this.accs = acc;
        this.isCloseAll = acc.getAttribute('data-acc-close');
        this.isInitOn = acc.hasAttribute('data-acc-init');
        this.navItems = null;
        this.contentItems = null;
        this.header = document.querySelector('#header');
        this.st = new Scrollto();
    }

    init(){
        this.registerEvents();
        let idName = location.hash.replace('#', '');
        if (idName) this.initOpen(idName);
    }

    registerEvents() {
        this.accSetting(this.accs);
        if (this.isCloseAll) document.addEventListener("click", this.closeAll.bind(this));
    }

    accSetting(acc) {
        this.navItems = [...acc.querySelectorAll('[data-acc-tab]')];
        this.contentItems = [...acc.querySelectorAll('[data-acc-item]')];
        this.navItems.map(function (navItem, i) {
            navItem.addEventListener("click", (e) => this.accClickHandler(e, i, acc));
            if (navItem.hasAttribute('data-acc-active')) {
                this.initOpenElement(navItem);
            }
        }, this);
        // init
        if (this.isInitOn) {
            const nav = acc.querySelector('[data-acc-tab]');
            const content = acc.querySelector('[data-acc-item]');
            this.setShowContent(nav, content, acc, 1);
        }
    }

    initOpen(id) {
        const ac = this.accs.querySelector(`#${id}`);
        if (ac) {
            const nav = ac.querySelector('[data-acc-tab]');
            const content = ac.querySelector('[data-acc-item]');
            this.setShowContent(nav, content, this.accs, 1);
        }
    }

    initOpenElement(navItem){
        let content = next(navItem);
        this.setShowContent(navItem, content, this.accs,true);
    }

    accClickHandler(e, i, parent) {
        e.preventDefault();
        const nav = e.currentTarget;
        if (this.isCloseAll) {
            e.stopPropagation();
            if (parent.pnav === nav) {
            } else {
                this.closeAll();
            }
        }
        const content = next(nav);
        this.setShowContent(nav, content, parent, true);
    }

    setShowContent(nav, content, parent, init = false) {
        if (!init) return false;
        let inner = content.querySelector('[data-acc-itemin]');
        if (inner) {
            this.setHeight(content, inner);
        } else {
            content.classList.toggle('active');
        }
        nav.classList.toggle('active');
        if (nav.classList.contains('active') && nav.getAttribute('data-acc-tab') === 'scroll') {
            this.scrolltoElement(nav);
        }
        parent.pnav = nav;
    }

    // scrolltoElement(nav) {
    //     let pos = getPosition(nav).y;
    //     this.st.animate(pos, 600, null
    //         , () => {
    //         this.header.classList.add('remove');
    //     });
    // }
    
    setHeight(element, inner) {
        let elementHeight;
        if (!element.isOpen) {
            elementHeight = inner.clientHeight;
            let height = 0;
            function frameOpen() {
                height = Math.floor((height + 20) * 1.3);
                element.style.height = `${height}px`;
                if (height <= elementHeight) {
                    window.requestAnimationFrame(frameOpen);
                } else {
                    element.removeAttribute("style");
                    element.classList.add('active');
                    element.isOpen = true;
                }
            }
            window.requestAnimationFrame(frameOpen);
        } else {
            elementHeight = inner.clientHeight;
            this.colseContent(element, elementHeight);
            element.isOpen = false;
        }
    }

    colseContent(elem, elementHeight) {
        let height = elementHeight;
        function frame() {
            height = Math.floor(height / 1.5);
            elem.style.height = height + 'px';
            if (height > 0) {
                window.requestAnimationFrame(frame);
            } else {
                elem.removeAttribute("style");
                elem.classList.remove('active');
            }
        }
        window.requestAnimationFrame(frame);
    }

    closeAll(e = null) {
        this.navItems.forEach(nav => nav.classList.remove('active'));
        this.contentItems.forEach(item => item.classList.remove('active'));
    }
}
