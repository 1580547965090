
export default class Flipslide {

    constructor() {
        this.container = document.querySelector('[data-flipslide]');
        this.cn = 0;
        this.pre = null;
    }

    init() {
        this.companyLists = [...this.container.querySelectorAll('.company-list')];
        this.next();
        this.tick = setInterval(() => {
            this.cn++;
            if (this.cn > 3) { this.cn = 0; }
            this.next();
        }, 5000);
    }

    next(){
        this.companyLists[this.cn].classList.add('active');
        if (this.pre) this.pre.classList.remove('active');
        this.pre = this.companyLists[this.cn];
    }

}
