
export default class SideNav {
  constructor() {
    this.utilNav = document.querySelector('.side-info');
    this.utilNavInner = document.querySelector('.side-info__inner');
    this.container = this.utilNav;
  }

  init() {
    this.assignment();
    this.onScroll();
    this.event();
  }

  assignment() {
    this.snavTriggers = [...document.querySelectorAll('[data-snav-trigger]')];
    this.snavTriMaxNum = this.snavTriggers.length;
    this.snavContentGeneral = document.querySelector('[data-snav-content="general"]');
    this.mainContent = document.querySelector('[data-subnav-stick-start]');
    this.dataSubnavStickEnd = document.querySelector('[data-subnav-stick-end]');
    this.mainContentIsInView = false;
    this.scrollHandle = this.onScroll.bind(this);
  }

  event() {
    window.addEventListener('scroll', (e) => this.scrollHandle(e));
  }

  onScroll() {
    this.checkStickSideNav();
    this.changeSideNav();
  }

  changeSideNav(){
    this.snavTriggers.forEach(item => {
      let snavTop = item.getBoundingClientRect().top < 50;
      let snavBtm = item.getBoundingClientRect().bottom > 50;
      const snavname = item.dataset.snavTrigger;
      const selector = `[data-snav-content="${snavname}"]`;
      let content = document.querySelector(selector);
      if (snavTop && snavBtm && content) {
        content.classList.add('active');
        this.snavContentGeneral.classList.remove('active');
      } else {
        if (content) content.classList.remove('active');
        this.checksnv++;
        if (this.checksnv >= this.snavTriMaxNum){
          this.snavContentGeneral.classList.add('active');
        }
      }
    });
    this.checksnv = 0;

  }

  checkStickSideNav(st) {
    var hpoint = window.innerHeight / 2;
    let ch = this.utilNav.clientHeight / 2;

    this.mainContentIsInView = this.mainContent.getBoundingClientRect().top < 0;
    this.endIsinview = this.dataSubnavStickEnd.getBoundingClientRect().top - this.utilNavInner.clientHeight < 0;
    if (this.mainContentIsInView && !this.endIsinview) {
      this.utilNav.classList.add('fixed');
      this.utilNav.classList.remove('end');
    } else if (this.endIsinview) {
      this.utilNav.classList.add('end');
    } else {
      this.utilNav.classList.remove('fixed');
      this.utilNav.classList.remove('end');
    }
  }


}
