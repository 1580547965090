/**
 * MC Ajax Submit JS
 */

'use strict';
import $ from 'jquery';

export default class ajaxSubmit {

  constructor(id) {
    this.container = document.getElementById(id);
    this.secondForm = document.querySelector('#secondForm');
  }

  init(formid) {
    this.setupElements(formid);
    this.registerEvents();
  }

  setupElements(formid) {
    // this.form = this.container.getElementById(formid);
    this.form = this.container.querySelector(`#${formid}`);
    this.resultsElm = this.container.querySelector('.results');
    this.resultsSuccessElm = this.resultsElm.querySelector('.results__success');
    this.resultsErrorElm = this.resultsElm.querySelector('.results__error');
    this.btnBack = this.resultsErrorElm.querySelector('.btn-back');
    this.inputTexts = [...this.container.querySelectorAll('input[type="text"],input[type="tel"],input[type="email"]')];
    this.selects = [...this.container.querySelectorAll('select')];
    this.proSelect = this.container.querySelector('#mce-PROGRAMS');
    this.inputSwitch = this.container.querySelector('[data-input-switch]');
  }

  registerEvents() {
    this.form.addEventListener("submit", this.onSubmit.bind(this));
    this.btnBack.addEventListener("click", () => this.setState("form"));

    this.inputTexts.forEach(input => input.addEventListener('focus', this.setFocus));
    this.inputTexts.forEach(input => input.addEventListener('blur', this.setBlur));

    this.selects.forEach(input => input.addEventListener('change', this.setSelects));

    if (this.proSelect && this.secondForm) this.hideEvent();
  }

  hideEvent() {
    this.proSelect.addEventListener('change', (e) => { this.setProSelects(e)});
  }

  setProSelects(e) {
    const element = e.currentTarget;
    // console.log(element.options[element.selectedIndex].hasAttribute('data-form-adult'));

    let selects = null;
    if (element.selectedIndex <= 0) {
      this.secondForm.classList.add('hidden');
    } else {
      this.secondForm.classList.remove('hidden');
    }
    switch (element.options[element.selectedIndex].hasAttribute('data-form-adult')) {
      case true:
        this.inputSwitch.classList.add('hiddenSelect');
        selects = this.inputSwitch.querySelector('select');
        selects.selectedIndex = "1";
        break;
    
      default:
        this.inputSwitch.classList.remove('hiddenSelect');
        selects = this.inputSwitch.querySelector('select');
        selects.selectedIndex = "0";
        break;
    }
  }


  setSelects(e) {
    const element = e.currentTarget;
    if (element.selectedIndex <= 0) {
      element.classList.remove('active');
    } else {
      element.classList.add('active');
    }
  }

  setFocus(e) {
    const element = e.currentTarget;
    element.classList.add('active');
    
  }
  setBlur(e) {
    const element = e.currentTarget;
    if (element.value == null || element.value == ""){
      element.classList.remove('active');
    }
    
  }


  onSubmit(e) {
    e.preventDefault();
    const ajaxOpts = this.getAjaxOptions.bind(this);
    return $.ajax(this.form.getAttribute('action'), {
      type: this.form.getAttribute('method'),
      data: $(this.form).serialize(),
      dataType: 'jsonp',
      contentType: "application/json; charset=utf-8",
      success: this.onSubmitSuccess.bind(this)
    });
  }

  onSubmitSuccess(res) {
    const { msg, result } = res;
    this.setState(result);

    if (result != "success") {
      this.resultsErrorElm.querySelector('.msg').innerHTML = msg
      return false;
    }
  }

  getAjaxOptions() {
    return {
      url: this.form.getAttribute('action'),
      type: this.form.getAttribute('method'),
      data: $(this.form).serialize(),
      dataType: 'jsonp',
      contentType: "application/json; charset=utf-8",
      success: this.onSubmitSuccess.bind(this)
    }
  }

  setState(state) {
    this.container.setAttribute("data-state", state);
  }


}
