import CustomBodymovin from './bodymovin';
import LoadingBM from '../json/goh_loading_v02.json';

export default class Loading {

  constructor() {
    this.loading = document.getElementById('loading');
    this.bmLoading = document.getElementById('loadingBM');
    this.loadComp = false;
  }

  init(comp) {
    this.setupLoadingBodyMovin();
    window.addEventListener('load', this.onLoad.bind(this), false);
    this.compFnc = comp;
  }

  setupLoadingBodyMovin() {
    this.loadingBmObj = new CustomBodymovin({
      elm: this.bmLoading,
      data: LoadingBM,
      // loop: true
      onComplete: () => {
        this.loadingBmObj.goToAndPlay(600);
      }
    });
    this.loadingBmObj.play();
  }

  onLoad() {
    if (this.loadComp) return false;
    setTimeout(() => {
      this.loading.classList.add('complete');
    }, 200);
    setTimeout(() => {
      this.compFnc();
      this.loadComp = true;
    }, 400);
  }

}
