/**
 * ajax Load Page JS
 */

import 'whatwg-fetch';
import Overlay from './overlay';
import Scrollto from './scrollto';
import Subpage from '../pages/subpage';

export default class AjaxLoadPage {

  constructor(ajaxlinks = null) {
    this.ajaxlinks = (ajaxlinks) ? ajaxlinks : [...document.querySelectorAll('[data-ajax-link]')];
    this.ajaxOverlay = document.querySelector('#ajaxOverlay');
    this.loading = document.querySelector('.loading');
    this.bmLogoLoading = document.getElementById('bmLogoLoading');
    this.newPage = null;
    this.subpage = null;
    this.pageurl = window.location.href.replace(/#.*$/, "");
    this.getURL = this.getURL.bind(this);
  }

  init() {
    this.st = new Scrollto();
    this.overlay = new Overlay();
    this.overlay.delegate(this.closeOverlay.bind(this));
    this.registerEvents(this.ajaxlinks);
  }

  registerEvents(ajaxlinks) {
    if (ajaxlinks) {
      ajaxlinks.forEach(link => link.addEventListener('click', this.getURL));
    }
  }
  resetEvents() {
    this.ajaxlinks.forEach(link => link.removeEventListener('click', this.getURL));
  }

  getURL(e) {
    e.preventDefault();
    const link = e.currentTarget;
    const resourceURI = link.href;
    // if (e.currentTarget.href === window.location.href) {
    //   return false;
    // }
    this.pageurl = resourceURI;
    let pos = window.pageYOffset;
    if (link.getAttribute('data-ajax-link') == 'min') {
      this.overlay.checkMiniWin(true);
    }
    if (!this.overlay.status) this.overlay.noscrollOverlay();

    this.fetchContent(resourceURI);
  }
  
  fetchContent(resourceURI) {
    this.overlay.openShowOverlay();
    fetch(resourceURI)
      .then(res => res.text())
      .then(page => this.renderContent(page))
  }

  renderContent(page) {
    this.overlay.openContainerInOverlay();
    let parser = new DOMParser();
    let doc = parser.parseFromString(page, 'text/html');
    this.newPage = doc.querySelector('#ajaxPage');

    this.swap(doc, '#ajaxPage');
    this.overlay.openContentInOverlay();
    this.overlay.topscroll();
    this.subpage = new Subpage(this.newPage);
    this.subpage.init(this.pageurl);
  }

  swap(doc, name) {
    let newContents = doc.querySelector(name);
    let ajaxOverlay = document.querySelector('#ajaxOverlay');
    this.ajaxPage = ajaxOverlay.querySelector('#ajaxPage');
    this.ajaxPage.parentNode.replaceChild(newContents, this.ajaxPage);
  }

  closeOverlay() {
    if (this.newPage) this.subpage.end(this.newPage);
    let content = document.querySelector('#ajaxPage');
    content.innerHTML = '';
  }

}

