
import Swiper from 'swiper';
import ajaxSubmit from '../modules/mcAjaxSubmit';
import AjaxLoadPage from '../modules/ajaxLoadPage';
import Scrollto from '../modules/scrollto';
import Accordion from '../modules/accordion';
import Tab from '../modules/tab';
import Overlay from '../modules/overlay';
import { getQueryString } from '../helpers/helpers';

export default class Subpage {

  constructor(pagebody) {
    this.body = document.querySelector('#ajaxOverlay');
    this.pagebody = pagebody;
    this.accs = [...pagebody.querySelectorAll('[data-acc]')];
    this.tabs = [...pagebody.querySelectorAll('[data-tab]')];
    this.quoteSlider = document.querySelector('.quote-container');
    this.btnCloses = [...pagebody.querySelectorAll('[data-overlay="close"]')];
    this.st = new Scrollto();
    this.contentJS = null;
    this.ajaxLinks = [...pagebody.querySelectorAll('[data-ajax-link]')];
  }

  init(url = null) {
    this.scrolls = [...this.pagebody.querySelectorAll('[data-scrollto]')];
    if (this.scrolls){
      this.scrolls.forEach(link => link.addEventListener('click', this.scrollTo.bind(this)));
    }

    this.tab = new Tab(this.tabs);
    let id = getQueryString('tab', url);
    if (this.tab.containers) this.tab.init(id);

    if (this.accs) {
      this.accs.forEach(acc => {
        let accObj = new Accordion(acc);
        accObj.init();
      });
    }
    if (this.btnCloses) {
      this.btnCloses.forEach(btn => 
        btn.addEventListener('click', () => {
          Overlay.staticClose();
        })
      );
    }
    if (this.ajaxLinks) {
      this.alp = new AjaxLoadPage(this.ajaxLinks);
      this.alp.init();
    }
    if (this.quoteSlider) {
      this.initSwiper();
    }
    this.mcAjaxSubmit = new ajaxSubmit('mc_embed_signup');
    if (this.mcAjaxSubmit.container) this.mcAjaxSubmit.init('mc-embedded-subscribe-form');
  }

  initSwiper() {
    new Swiper(this.quoteSlider, {
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      autoplay: {
        delay: 6000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

  scrollTo(e) {
    e.preventDefault();
    let element = e.currentTarget;
    let name = element.dataset.scrollto;
    const selector = `#${name}`;
    const content = this.pagebody.querySelector(selector);
    this.st.animate(content.offsetTop, 1000, this.body);
  }

  end(pagebody) {
    if (this.contentJS) {
      this.contentJS.end();
    }
  }

}
