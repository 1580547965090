
/**
 * Home Chinese Intro JS
 */

import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';
import CustomBodymovin from '../modules/bodymovin';
import LABM from '../json/web_wave_green_white.json';
import TextFx from '../vender/textfx';

export default class HomeChineseIntro {

  constructor() {
    this.body = document.querySelector('body');
    this.slideshow = document.querySelector('.slideshow');
    this.revealEles = [...document.querySelectorAll(".reveal-item")];
    this.bmLineAnimation = document.getElementById('bmLineAnimation');
    this.anime = new anime();
    this.titles = [...document.querySelectorAll('.title')];
    this.events();
  }

	/**
	 * Event listeners
	 */
  events() {


    this.setupLineAnimeBodyMovin();
    this.slideshow.classList.remove('init');
    anime({
      targets: this.slideshow,
      duration: 1200,
      opacity: 1.0,
      complete: () => {
        setTimeout(() => {
          this.titles.forEach(title => {
            let text = new TextFx(title);
            this.effect = (title.hasAttribute('data-effect')) ? title.getAttribute('data-effect') : 'fxgoh';
            text.show(this.effect);
          });
        }, 50);

      }
    });

  }

  setupLineAnimeBodyMovin() {
    this.lineAnimeBmObj = new CustomBodymovin({
      elm: this.bmLineAnimation,
      data: LABM,
      loop: true
    });
    this.lineAnimeBmObj.play();

  }
}
