
import Swiper from 'swiper';
export default class PanelSlideshow {

    constructor() {
        this.container = document.querySelector('.panel-wrap');
    }

    init() {
        this.panelSwiper = new Swiper(this.container, {
            speed: 1000,
            slidesPerView: 4,
            spaceBetween: 20,
            // slidesPerGroup: 4,
            loop: true,
            breakpoints: {
                1080: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            },
            loopFillGroupWithBlank: true,
            navigation: {
                prevEl: '.panel-nav-left',
                nextEl: '.panel-nav-right',
            },
            autoplay: {
                delay: 4000,
                waitForTransition: false,
                disableOnInteraction: false
            },
        });
    }

}
