
import Swiper from 'swiper';
import CustomBodymovin from '../modules/bodymovin';
import HomeChineseIntro from './home_chintro';
import AjaxLoadImage from '../modules/ajaxLoadImage';
import gsap from 'gsap';

export default class Home {

  constructor() {
    this.body = document.querySelector('body');
    this.homecover = document.querySelector('.slideshow-wrap');
    this.sss = [...document.querySelectorAll('.slideshow-wrap')];
    this.slideshow = document.querySelector('.slideshow');
    this.slideitems = [...document.querySelectorAll('.swiper-slide')];
    this.homecoverNav = [...document.querySelectorAll('.slideshow-navss__item')];
    this.popup = document.querySelector('[data-popup]');
    this.locationselect = document.querySelector('.location-overlay');
    this.init();
  }

  init() {
    if (this.body.classList.contains('page-template-page-home-ch')) {
      new HomeChineseIntro();
    }
    if (this.body.classList.contains('programs-template-default')) {
      this.sss.forEach(item => {
        let sitems = [...item.querySelectorAll('.swiper-slide')];
        if (sitems.length > 1) this.initSwiperShort();
      });
    } else {
      this.sss.forEach(item => {
        let sitems = [...item.querySelectorAll('.swiper-slide')];
        if (sitems.length > 1) this.initSwiper();
      });
    }

    if ( this.locationselect && document.referrer === '' ) {
      const keyName = 'visited';
      const keyValue = true;
  
      if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        document.querySelector('.location-overlay').classList.add('active')

        gsap.timeline()
          .to('.location-overlay .big-g', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0)
          .to('.location-overlay__logo', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0)
          .to('.location-overlay__welcome', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0.2)
          .to('.location-overlay__select-label', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0.4)
          .to('.location-overlay__options ul:first-child', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0.6)
          .to('.location-overlay__options ul:last-child', 1, { opacity : 1, transform : 'translate3d(0,0,0)' }, 0.8)

        document.querySelector('.location-overlay .btn__main-street').addEventListener('click', function() {
          this.locationselect.style.opacity = 0;
          setTimeout(function() {
            this.locationselect.style.display = 'none';
          }.bind(this), 1000);

        }.bind(this));
  
      }
    }

    if(this.popup) {
      let ajaxLoadImage = new AjaxLoadImage();
      ajaxLoadImage.init();
      ajaxLoadImage.setURL(this.popup.dataset.popup);
      ajaxLoadImage.setClass(this.popup.dataset.popupClass);
      ajaxLoadImage.setLink(this.popup.dataset.popupLink);
    }
  }

  initSwiper() {
    this.homeSwiper = new Swiper(this.homecover, {
      speed: 1000,
      slidesPerView: 'auto',
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      navigation: {
        nextEl: '.slideshow-nav-left',
        prevEl: '.slideshow-nav-right',
      },
      autoplay: {
        delay: 6000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

  initSwiperShort() {
    this.homeSwiper = new Swiper(this.homecover, {
      speed: 1000,
      slidesPerView: 'auto',
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      navigation: {
        nextEl: '.slideshow-nav-left',
        prevEl: '.slideshow-nav-right',
      },
      autoplay: {
        delay: 4000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

}
