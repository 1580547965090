
/**
 * Calendar JS
 */

import Scrollto from '../modules/scrollto';
import CalendarView from './calendarView';
import { getPosition, parseQueryObjectFromString } from '../helpers/helpers';

export default class Calendar {

  constructor() {
    this.container = document.querySelector('.page-template-page-calendar');
    this.containerDay = document.querySelector('.page-template-page-eventdaily');
  }

  init() {
    this.view = new CalendarView();
    this.view.init();
    this.miniLoading = document.querySelector('.mini-loading');
    this.miniLoading.classList.add('off');
  }

  dayinit() {
    this.st = new Scrollto();
    this.eventdayContents = [...document.querySelectorAll('[data-event-day-empty]')];
    this.eventdayContents.forEach(item => {
      const id = item.getAttribute('data-event-day-empty');
      const classname = `[data-event-day="${id}"]`;
      const target = document.querySelector(classname);
      target.remove();
    });
    
    let query = window.location.search.substring(1);
    let idName = location.hash.replace('#', '');
    let parObj = (query) ? parseQueryObjectFromString(query) : {};
    if (parObj['eventday']){
      const id = parObj['eventday'];
      const classname = `[data-event-day="${id}"]`;
      const target = document.querySelector(classname);
      if(target) {
        const pos = getPosition(target).y;
        this.st.animate(pos, 800);
      }
    }
  }

}
