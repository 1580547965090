
export default class Loopsilde {

    constructor() {
        this.container = [...document.querySelectorAll('[data-loopsilde]')];
    }

    init() {
        this.container.forEach(item => {
            let imgs = [...item.querySelectorAll('img')],
                imgNum = imgs.length, 
                initObj = {
                    "duration": 5000, 
                    "pause": 0
                };
            if (item.hasAttribute('data-loopsilde')) {
                let alldata = item.getAttribute('data-loopsilde');
                if (alldata) {
                    let obj = JSON.parse(alldata);
                    initObj.duration = (!obj.duration) ? initObj.duration : obj.duration;
                    initObj.pause = (!obj.pause) ? initObj.pause : obj.pause;
                }
            }

            setTimeout(() => {
                imgs.cn = 0;
                this.next(imgs);
                setInterval(() => {
                    imgs.cn++;
                    if (imgs.cn >= imgNum) { imgs.cn = 0; }
                    this.next(imgs);
                }, initObj.duration);
            }, initObj.pause);

        });

    }

    next(imgs){
        imgs[imgs.cn].classList.add('active');
        if (imgs.pre) imgs.pre.classList.remove('active');
        imgs.pre = imgs[imgs.cn];
    }

}
