
import ajaxSubmit from './mcAjaxSubmit';

export default class Footer {

  constructor() {
    this.container = document.querySelector('.footer');
  }

  init() {

    this.mcAjaxSubmitFooter = new ajaxSubmit('mc_embed_signup_footer');
    if (this.mcAjaxSubmitFooter.container) this.mcAjaxSubmitFooter.init('mc-embedded-subscribe-form-footer');

    const siteMapTrigger = document.querySelector('.sitemap-trigger')
    const siteMap = document.querySelector('.footer__info__nav__container');
    const closeBtn = document.querySelector('.footer__info__nav__closebtn');

    siteMapTrigger.addEventListener('click', e => {
      siteMapTrigger.classList.toggle('active');
      siteMap.classList.toggle('active');
    })

    closeBtn.addEventListener('click', e => {
      siteMap.classList.remove('active');
    })

  }


}
