export default class LocationTab {

  constructor() {
      this.container = document.querySelector('.location-tab');
  }

  init() {
      this.toggleTab();
  }

  toggleTab(){
      const button = document.querySelector('.location-tab__button');

      button.addEventListener('click', e => {
          this.container.classList.toggle('is-active');
      });
  }

}
