
import Swiper from 'swiper';
import AjaxLoadImage from '../modules/ajaxLoadImage';

export default class TorontoLanding {

  constructor() {
    this.body = document.querySelector('body');
    this.slideslim = document.querySelector('.slide-slim');
    this.slides = [...document.querySelectorAll('.slide-slim-item')];
    this.nexttxt = document.querySelector('.slide-slim-button-next_txt');
    this.panel = document.querySelector('.panel-wrap');
    this.popup = document.querySelector('[data-popup]');
    this.timerOn = null;
    this.current = 0;
    this.mn = this.slides.length;
    // this.init();
    // if (this.panel) this.panelinit();
  }


  panelinit() {

    this.panelSwiper = new Swiper(this.panel, {
      speed: 1000,
      slidesPerView: 4,
      spaceBetween: 20,
      // slidesPerGroup: 4,
      loop: true,
      breakpoints: {
        1080: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        }
      },
      loopFillGroupWithBlank: true,
      navigation: {
        prevEl: '.panel-nav-left',
        nextEl: '.panel-nav-right',
      },
      autoplay: {
        delay: 3000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

  init() {
    const that = this;
    this.homeSwiper = new Swiper(this.slideslim, {
      speed: 1300,
      // slidesPerView: 'auto',
      slidesPerView: 1,
      // loopAdditionalSlides:1,
      // loopedSlides:2,
      // virtualTranslate: true, 
      watchSlidesVisibility: true,
      effect: "myCustomTransition", 
      loop: true,
      navigation: {
        nextEl: '.slide-slim-button-next',
        prevEl: '.slide-slim-button-prev',
      },
      autoplay: {
        delay: 4000,
        waitForTransition: false,
        disableOnInteraction: false
      },
      on: {
        slideChangeTransitionStart: function() {
          var swiper = this;
          
          var nextn = that.geSlideDataIndex(swiper) + 1;
          if(that.mn <= nextn) nextn = 0;
          // console.log(that.mn, nextn);

          var nextEle = that.slides[nextn];
          var nextFigcaption = nextEle.querySelector("figcaption");
          that.nexttxt.classList.add("out");

          if(that.current == swiper.realIndex) clearTimeout(that.timerOn);
          that.timerOn = setTimeout(() => {
            that.nexttxt.textContent = nextFigcaption.innerText;
            that.nexttxt.classList.remove("out");
            that.current = swiper.realIndex;
          }, 800);
          // if(that.nexttxt.classList.contains("out")){
          //   that.nexttxt.classList.remove("out");
          // }
        },
        
      }
    });
  
  }

  initpopup() {
    if(this.popup) {
      let ajaxLoadImage = new AjaxLoadImage();
      ajaxLoadImage.init();
      ajaxLoadImage.setURL(this.popup.dataset.popup);
      ajaxLoadImage.setClass(this.popup.dataset.popupClass);
      ajaxLoadImage.setLink(this.popup.dataset.popupLink);
      // console.log(this.popup.dataset.popup);
    }
  }

  geSlideDataIndex(swipe){
    var activeIndex = swipe.activeIndex;
    var slidesLen = swipe.slides.length;
    if(swipe.params.loop){
        switch(swipe.activeIndex){
            case 0:
                activeIndex = slidesLen-3;
                break;
            case slidesLen-1:
                activeIndex = 0;
                break;
            default:
                --activeIndex;
        }
    }
    return  activeIndex;
}
}
