
import Scrollto from '../modules/scrollto';
import { getPosition } from '../helpers/helpers';

export default class CalendarView {

  constructor() {
  }
  
  init() {
    this.st = new Scrollto();
    this.ticking = false;
    this.eventMonthlyLinks = [...document.querySelectorAll('[data-event-link]')];
    this.cheader = document.querySelector('.cheader');
    this.eventWeeks = [...document.querySelectorAll('.main .event__week')];
    this.headEventWeek = document.querySelector('.cheader .event__week');
    this.headEventWeekDays = [...this.headEventWeek.querySelectorAll('.event__day')];
    this.headEventWeekDaysNums = [...this.headEventWeek.querySelectorAll('.event__day-num')];
    this.tops = this.headEventWeek.getBoundingClientRect().top;
    this.bottoms = this.headEventWeek.getBoundingClientRect().bottom;
    this.month = document.querySelector('[data-dropdown-nav="monthly"] .innerText');
    this.eventMonthlyContents = [...document.querySelectorAll('[data-event-link-target]')];
    this.eventMonthlyLinks.forEach(item =>
      item.addEventListener('click', (e) => this.movetoTarget(e))
    );
    this.accSet();
    setInterval(this.checking.bind(this), 100);
  }

  movetoTarget(e) {
    const id = e.currentTarget.getAttribute('data-event-link');
    const classname = `[data-event-link-target="${id}"]`;
    const target = document.querySelector(classname);
    const pos = getPosition(target).y - 120;
    this.st.animate(pos, 600);
    e.preventDefault();
  }

  accSet() {
    let items = [...document.querySelectorAll('.btn-acc')];
    items.forEach(item => {
      item.addEventListener('click', this.accClose)
    });
  }

  accClose(e) {
    let element = e.target.previousSibling;
    element.classList.toggle('active');
  }

  checking() {
    this.last_pos = window.scrollY;
    this.eventWeeks.forEach(item => {
      if (item.getBoundingClientRect().top < this.bottoms) {
        item.classList.add('current'); 
        this.changeDays(item);
      } else {
        item.classList.remove('current');
      }
    });
    let cheaderHight = this.cheader.offsetHeight;
    this.eventMonthlyContents.forEach(item => {
      switch (this.last_pos < this.before_pos) {
        case true:
          if (item.getBoundingClientRect().bottom > cheaderHight) {
            if (item.lock) {
              item.lock = false;
              this.changeText(item);
            }
          }
          break;
        case false:
          if (item.getBoundingClientRect().top < cheaderHight) {
            if (!item.lock) {
              item.lock = true;
              this.changeText(item);
            }
          }
          break;
      }
    });
    this.before_pos = this.last_pos;
  }

  changeText(item) {
    let id = item.getAttribute('data-event-link-target');
    let classname = `[event-month-title="${id}"]`;
    let target = document.querySelector(classname);
    this.month.innerText = target.textContent;
  }

  changeDays(item) {
    let days = [...item.querySelectorAll('.event__day')];
    let nums = [];
    for (let i = 0; i < 7; i++) {
      const day = days[i];
      let num = (day) ? day.querySelector('.event__day-num') : null;
      let isEvent = (day) ? day.querySelector('.event__card') : false;
      if (isEvent) {
        this.headEventWeekDays[i].classList.remove('empty');
      } else {
        this.headEventWeekDays[i].classList.add('empty');
      }
      let daysnum = (num) ? num.textContent : null;
      this.headEventWeekDaysNums[i].textContent = daysnum;
    }
  }

}