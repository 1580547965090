'use strict'

import 'babel-polyfill'
import scrollMonitor from 'scrollmonitor'
import Home from './pages/home'
import Calendar from './pages/calendar'
import Header from './modules/header'
import Footer from './modules/footer'
import Loading from './modules/loading'
import FeatureNews from './modules/featurenews'
import AjaxLoadPage from './modules/ajaxLoadPage'
import Accordion from './modules/accordion'
import Tab from './modules/tab'
import SideNav from './modules/sidenav'
import Flipslide from './modules/flipslide'
import Loopsilde from './modules/loopsilde'
import PanelSlideshow from './modules/panelSlideshow'
import TorontoLanding from './pages/toronto_landing'
import ajaxSubmit from './modules/mcAjaxSubmit'
import ajaxSubmitTab from './modules/mcAjaxSubmitTab'
import LocationTab from './modules/locationTab'

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.revealEles = [...document.querySelectorAll('.reveal-item')]
    this.accs = [...document.querySelectorAll('[data-acc]')]
    this.loading = new Loading()
    if (this.loading.loading) this.loading.init(this.contentInit.bind(this))
    setTimeout(() => {
      this.loading.onLoad()
    }, 4000) //forced finish
  }

  contentInit() {
    this.home = new Home()

    this.header = new Header()
    if (this.header.container) this.header.init()
    this.footer = new Footer()
    if (this.footer.container) this.footer.init()
    this.featurenews = new FeatureNews()
    if (this.featurenews.container) this.featurenews.init()
    // this.calender = new Calendar();
    // if (this.calender.container) this.calender.init();
    // if (this.calender.containerDay) this.calender.dayinit();
    this.sidenav = new SideNav()
    if (this.sidenav.container) this.sidenav.init()
    this.flipslide = new Flipslide()
    if (this.flipslide.container) this.flipslide.init()
    this.loopsilde = new Loopsilde()
    if (this.loopsilde.container) this.loopsilde.init()
    this.panelSS = new PanelSlideshow()
    if (this.panelSS.container) this.panelSS.init()
    this.tab = new Tab()
    if (this.tab.containers) this.tab.init()
    this.alp = new AjaxLoadPage()
    if (this.alp.ajaxlinks) this.alp.init()

    this.locationTab = new LocationTab()
    if (this.locationTab.container) this.locationTab.init()

    this.mcAjaxSubmit = new ajaxSubmit('mc_embed_signup')
    if (this.mcAjaxSubmit.container) this.mcAjaxSubmit.init('mc-embedded-subscribe-form')

    this.mcAjaxSubmitTab = new ajaxSubmitTab('mc_embed_signup_tab')
    if (this.mcAjaxSubmitTab.container) this.mcAjaxSubmitTab.init('mc-embedded-tab-subscribe-form')

    if (this.accs) {
      this.accs.forEach(acc => {
        let accObj = new Accordion(acc)
        accObj.init()
      })
    }

    if (this.revealEles) {
      this.revealEles.forEach(ele => {
        if (!ele) return false
        var elementWatcher = scrollMonitor.create(ele, -100)
        elementWatcher.enterViewport(function() {
          if (this.watchItem.classList.contains('reveal-item')) {
            this.watchItem.classList.remove('reveal-item')
          }
        })
      })
    }

    if (this.body.classList.contains('page-template-page-toronto-landing')) {
      let tl = new TorontoLanding()
      tl.initpopup()
    }
  }
}

window.App = new App()
