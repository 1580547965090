/*
Name: Scroll To
Desc: Animates the scroll to a specific pixel value with a given duration.

Usage:
import st from './scrollto'
st.animate(element.offsetTop, 1000, context)

*/

'use strict';

export default class Scrollto {
    
    constructor() {
        this.contextHTML = document.documentElement;
        this.contentBODY = document.body;
        this.context = null;
    }

    animate(to, duration, context, complete) {

        let start;

        if (context) {
            start = context.scrollTop;
        } else {
            start = document.documentElement.scrollTop + document.body.scrollTop;
        }

        let change = to - start;
        let increment = 20;

        let anim = (elapsedTime) => {
            elapsedTime += increment;
            let position = this.easeInOut(elapsedTime, start, change, duration);

            if (context) {
                context.scrollTop = position;
            } else {
                document.documentElement.scrollTop = position;
                document.body.scrollTop = position;
            }

            if (complete) {
                if (Math.ceil(position) === to) {
                    complete();
                }
            }else {
                if (Math.ceil(position) === to) {
                    return false;
                }
            }

            if (elapsedTime < duration) {
                setTimeout(() => anim(elapsedTime), increment);
                return false;
            }
        }

        anim(0);
    }

    easeInOut(currentTime, start, change, duration) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return change / 2 * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }

}
