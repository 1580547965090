import {
  findAncestor
} from '../helpers/helpers';

export default class Tab {
  constructor(tabs = null) {
    this.containers = (tabs) ? tabs : [...document.querySelectorAll('[data-tab]')];
    this.content = null;
  }

  init(id = null) {
    this.registerEvents();
    if (id) this.initSet(id);
  }

  initSet(id) {
    const linkSelector = `[data-tab-link="${id}"]`;
    const itemSelector = `[data-tab-item="${id}"]`;
    let initNav = document.querySelector(linkSelector);
    let initContent = document.querySelector(itemSelector);
    let initTab = findAncestor(initNav, 'data-tab');
    this.setShowContent(initNav, initContent, initTab);
  }

  registerEvents() {
    this.containers.forEach(tab => {
      this.tabSetting(tab);
    });
  }

  tabSetting(tab) {
    const navItems = [...document.querySelectorAll('[data-tab-link]')];
    this.nav = tab.querySelector('[data-tab-links]');
    navItems.map(function (navItem, i) {
      navItem.addEventListener("click", (e) => this.tabClickHandler(e, i, tab));
    }, this);

    // init
    const nav = tab.querySelector('[data-tab-link].active');
    const content = tab.querySelector('[data-tab-item].active');
    this.setShowContent(nav, content, tab);

  }

  tabClickHandler(e, i, parent) {
    e.preventDefault();
    const nav = e.currentTarget;
    if (parent.pnav.dataset.tabLink === nav.dataset.tabLink) return false;
    const name = nav.dataset.tabLink;
    const selector = `[data-tab-item="${name}"]`;
    const content = parent.querySelector(selector);

    const navSelector = `[data-tab-link="${name}"]`;
    const navActive = this.nav.querySelector(navSelector);
    this.setShowContent(navActive, content, parent);
  }

  setShowContent(nav, content, parent) {
    this.parent = parent; //for resize
    this.content = content;
    const tabWrap = parent.querySelector('.intro-tab');
    content.classList.add('active');
    nav.classList.add('active');
    if (parent.pcontent) {
      parent.pcontent.classList.remove('active');
      parent.pnav.classList.remove('active');
    }
    parent.pcontent = content;
    parent.pnav = nav;
  }

}